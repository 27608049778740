import React from "react"
import BasicText from "../../components/BasicText"
import LayoutBasic from "../../components/LayoutBasic"
import AnswerBox from "../../components/AnswerBox"
import Seo from "../../components/Seo"
import QuestionAndAnswerToggle from "../../components/QuestionAndAnswerToggle"
import RubyConvert from "../../components/RubyConvert"
import RubyConverterTool from "../../utils/RubyConverterTool"
import CenterContentLayout from "../../components/CenterContentLayout"
import css from "./stylesheets/faq.module.scss"

export default props => (
  <div>
    <Seo title={`FAQ`} />
    <LayoutBasic lang={`easyja`}>
      <BasicText>
        <CenterContentLayout narrow={true}>
          <h1>FAQ</h1>
          <p>
            <RubyConvert>{`{よく}{ある}{[質問/しつもん]です。}`}</RubyConvert>
            <br />
            <RubyConvert>{`{みなさんの}{[生活/せいかつ]の}{[役/やく]に[立/た]つ}{[団体/だんたい]や、}{サービス}{なども}{[紹介/しょうかい]しています。}`}</RubyConvert>
          </p>

          <div>
            <QuestionAndAnswerToggle
              isTall={true}
              fullWidth={true}
              question={RubyConverterTool(
                `{[困/こま]ったら}{[誰/だれ]に}{[相談/そうだん]すれば}{いいですか？}`
              )}
            >
              <AnswerBox>
                <p>
                  <RubyConvert>{`{まずは、}{[一緒/いっしょ]に}{[働/はたら]いている}{[人/ひと]や、}{[監理/かんり][団体/だんたい]・[登録/とうろく][支援/しえん][団体/だんたい]に}{[相談/そうだん]してください。}{それでも}{[解決/かいけつ]しなかった}{[場合/ばあい]は、}{いろいろな}{[相談/そうだん][窓口/まどぐち]が}{あります。}{[相談/そうだん]は}{すべて}{[無料/むりょう]です。}
                `}</RubyConvert>
                  <br />
                  <RubyConvert>{`{『[法/ほう]テラス』では、}{[通訳/つうやく]と}{[弁護士/べんごし]と}{3[人/にん]で}{[話/はな]す}{ことが}{できます。}{[仕事/しごと]、}{[事故/じこ]、}{[借金/しゃっきん]}{から、}{[災害/さいがい]に}{[関/かん]する}{ことまで、}{[相談/そうだん][内容/ないよう]に}{あわせて、}{[日本/にほん]の}{[法律/ほうりつ]や}{[専門家/せんもんか]を}{[無料/むりょう]で}{[紹介/しょうかい]してくれます。}{[弁護士/べんごし]に}{[頼/たの]む}{お[金/かね]を}{[貸/か]してくれる}{サービスも}{あります。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{『ASIAN}{PEOPLE’S}{FRIENDSHIP}{SOCIETY』では、}{トラブルの}{ほかに、}{[生活/せいかつ]についても}{[相談/そうだん]が}{できます。}`}</RubyConvert>
                  <br />

                  <RubyConvert>{`{『[東京/とうきょう][労働/ろうどう][局/きょく]』では、}{[仕事/しごと]についての}{[相談/そうだん]が}{[電話/でんわ]で}{できます。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{『[法務局/ほうむきょく]』では、}{[人権/じんけん]についての}{[相談/そうだん]が}{[電話/でんわ]・メール}{などで}{できます。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{[外国人/がいこくじん][介護者/かいごしゃ]は、}{『[国際/こくさい][厚生/こうせい][事業/じぎょう][団/だん]』に}{[電話/でんわ]・メール・Facebook・LINEで}{[相談/そうだん]が}{できます。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{[技能/ぎのう][実習/じっしゅう][生/せい]は、}{『[外国人/がいこくじん][技能/ぎのう][実習/じっしゅう][機構/きこう]』に}{[電話/でんわ]・メールで}{[相談/そうだん]が}{できます。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{また、}{[住/す]んでいる}{[地域/ちいき]によっては、}{[都道府県/とどうふけん]や}{[市区町村/しくちょうそん]の}{「[役所/やくしょ]」や、}{「[国際/こくさい][交流/こうりゅう][協会/きょうかい]」に}{[相談/そうだん][窓口/まどぐち]が}{あります。}`}</RubyConvert>
                </p>
                <div class={css.links}>
                  <p>
                    <RubyConvert>{`{[日本/にほん]での}{[弁護士/べんごし]への}{[相談/そうだん][窓口/まどぐち]}｜`}</RubyConvert>
                    <a
                      href={`https://www.houterasu.or.jp/multilingual/index.html`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      <RubyConvert>{`{[法/ほう]テラス$}`}</RubyConvert>
                    </a>
                    <br />
                    <small>
                      (
                      <RubyConvert>{`{やさしい[日本語/にほんご]}／{[日本語/にほんご]}／{[英語/えいご]}／{[中国語/ちゅうごくご]}／{[韓国/かんこく]・[朝鮮語/ちょうせんご]}／{フィリピノ[語/ご]}／{ベトナム[語/ご]}／{タイ[語/ご]}／{ネパール[語/ご]}／{スペイン[語/ご]}／{ポルトガル[語/ご]}`}</RubyConvert>
                      )
                    </small>
                  </p>
                  <p>
                    <RubyConvert>{`{[非/ひ][営利/えいり][団体/だんたい]（Nonprofit}{Organization）による}{[相談/そうだん][窓口/まどぐち]}｜`}</RubyConvert>
                    <a
                      href={`http://apfs.jp/talkwithapfs`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      <RubyConvert>{`{ASIAN}{PEOPLE’S}{FRIENDSHIP}{SOCIETY$}`}</RubyConvert>
                    </a>
                    <br />
                    <small>
                      (
                      <RubyConvert>{`{[英語/えいご]}／{[中国語/ちゅうごくご]}／{[韓国/かんこく]・[朝鮮語/ちょうせんご]}／{フィリピノ[語/ご]}／{ビルマ[語/ご]}／{ベンガル[語/ご]}／{ウルドゥー[語/ご]}／{ペルシャ[語/ご]}／{フランス[語/ご]}`}</RubyConvert>
                      )
                    </small>
                  </p>
                  <p>
                    <RubyConvert>{`{[日本/にほん]の}{[外国人/がいこくじん]のための}{[労働/ろうどう][相談/そうだん][窓口/まどぐち]}｜`}</RubyConvert>
                    <a
                      href={`https://jsite.mhlw.go.jp/tokyo-roudoukyoku/news_topics/kyoku_oshirase/roudou_kijun/20050415-foreigner.html`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      <RubyConvert>{`{[東京/とうきょう][労働/ろうどう][局/きょく]$}`}</RubyConvert>
                    </a>
                    <br />
                    <small>
                      (
                      <RubyConvert>{`{[日本語/にほんご]}／{[英語/えいご]}／{[中国語/ちゅうごくご]}／{フィリピノ[語/ご]}／{ベトナム[語/ご]}／{ビルマ[語/ご]}／{ベンガル[語/ご]}／{ネパール[語/ご]}／{スペイン[語/ご]}／{ポルトガル[語/ご]}`}</RubyConvert>
                      )
                    </small>
                  </p>
                  <p>
                    <RubyConvert>{`{[日本/にほん]の}{[外国人/がいこくじん]のための}{[人権/じんけん][相談/そうだん][窓口/まどぐち]}｜`}</RubyConvert>
                    <a
                      href={`http://www.moj.go.jp/JINKEN/jinken21.html`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      <RubyConvert>{`{[法務省/ほうむしょう]$}`}</RubyConvert>
                    </a>
                    <br />
                    <small>
                      (
                      <RubyConvert>{`{[日本語/にほんご]}／{[英語/えいご]}／{[中国語/ちゅうごくご]}／{[韓国/かんこく]・[朝鮮語/ちょうせんご]}／{フィリピノ[語/ご]}／{ベトナム[語/ご]}／{ポルトガル[語/ご]}`}</RubyConvert>
                      )
                    </small>
                  </p>
                  <p>
                    <RubyConvert>{`{[日本/にほん]が}{[認/みと]めた}{[外国人/がいこくじん][介護者/かいごしゃ]のための}{[相談/そうだん][窓口/まどぐち]}｜`}</RubyConvert>
                    <a
                      href={`https://jicwels.or.jp/?page_id=11193`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      <RubyConvert>{`{[国際/こくさい][厚生/こうせい][事業/じぎょう][団/だん]$}`}</RubyConvert>
                    </a>
                    <br />
                    <small>
                      (
                      <RubyConvert>{`{[日本語/にほんご]}／{[英語/えいご]}／{[中国語/ちゅうごくご]}／{フィリピノ[語/ご]}／{ベトナム[語/ご]}／{インドネシア[語/ご]}／{ビルマ[語/ご]}`}</RubyConvert>
                      )
                    </small>
                  </p>
                  <p>
                    <RubyConvert>{`{[日本/にほん]が}{[認/みと]めた}{[技能/ぎのう][実習/じっしゅう][生/せい]のための}{[相談/そうだん][窓口/まどぐち]}｜`}</RubyConvert>
                    <a
                      href={`https://www.otit.go.jp`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      <RubyConvert>{`{[外国人/がいこくじん][技能/ぎのう][実習/じっしゅう][機構/きこう]$}`}</RubyConvert>
                    </a>
                    <br />
                    <small>
                      (
                      <RubyConvert>{`{[英語/えいご]}／{[中国語/ちゅうごくご]}／{フィリピノ[語/ご]}／{ベトナム[語/ご]}／{カンボジア[語/ご]}／{タイ[語/ご]}／{インドネシア[語/ご]}／{ビルマ[語/ご]}`}</RubyConvert>
                      )
                    </small>
                  </p>
                </div>
              </AnswerBox>
            </QuestionAndAnswerToggle>
            <QuestionAndAnswerToggle
              isTall={true}
              fullWidth={true}
              question={RubyConverterTool(
                `{[病気/びょうき]に}{なったら}{どう}{したら}{いいですか？}`
              )}
            >
              <AnswerBox>
                <p>
                  <RubyConvert>{`{[日本/にほん]では、}{[母国語/ぼこくご]を}{[使/つか]える}{[病院/びょういん]が}{[少/すく]ないです。}{[日本語/にほんご]を}{[話/はな]せる}{[人/ひと]と}{[一緒/いっしょ]に、}{[病院/びょういん]へ}{[行/い]ってください。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{[日本語/にほんご]を}{[話/はな]せる}{[人/ひと]が}{いない}{[場合/ばあい]は、}{『AMDA[国際/こくさい][医療/いりょう][情報/じょうほう]センター』が}{[母国語/ぼこくご]を}{[使/つか]える}{[病院/びょういん]を}{[探/さが]したり、}{[通訳/つうやく]を}{してくれます。}{[電話/でんわ]で}{[相談/そうだん]する}{ことが}{できます。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{[病院/びょういん]には、}{[次/つぎ]の}{[書類/しょるい]を}{[持/も]っていってください。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`・{[健康保険/けんこうほけん][証/しょう]}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`・{[在留/ざいりゅう]カードか}{パスポート}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{また、}{アレルギーや}{[飲/の]んでいる}{[薬/くすり]、}{[宗教/しゅうきょう][上/じょう]の}{[理由/りゆう]で}{[生活/せいかつ]や}{[治療/ちりょう]に}{[制限/せいげん]が}{ある}{[場合/ばあい]は、}{はじめに}{[看護/かんご][師/し]に}{[伝/つた]えましょう。}`}</RubyConvert>
                </p>
                <div class={css.links}>
                  <p>
                    <RubyConvert>{`{[非/ひ][営利/えいり][団体/だんたい]（Nonprofit}{Organization）による}{[医療/いりょう][相談/そうだん][窓口/まどぐち]}｜`}</RubyConvert>
                    <a
                      href={`https://www.amdamedicalcenter.com`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      <RubyConvert>{`{AMDA[国際/こくさい][医療/いりょう][情報/じょうほう]センター$}`}</RubyConvert>
                    </a>
                    <br />
                    <small>
                      (
                      <RubyConvert>{`{やさしい[日本語/にほんご]}／{[英語/えいご]}／{[中国語/ちゅうごくご]}／{[韓国/かんこく]・[朝鮮語/ちょうせんご]}／{フィリピノ[語/ご]}／{ベトナム[語/ご]}／{タイ[語/ご]}／{スペイン[語/ご]}／{ポルトガル[語/ご]}`}</RubyConvert>
                      )
                    </small>
                  </p>
                </div>
              </AnswerBox>
            </QuestionAndAnswerToggle>
            <QuestionAndAnswerToggle
              isTall={true}
              fullWidth={true}
              question={RubyConverterTool(
                `{[日本語/にほんご]が}{うまくなるには}{どう}{したら}{いいですか？}`
              )}
            >
              <AnswerBox>
                <p>
                  <RubyConvert>{`{[日本語/にほんご]を、}{[毎日/まいにち]、}{[書/か]く、}{[読/よ]む、}{[聞/き]く、}{[話/はな]す}{ことが}{[大事/だいじ]です。}{[周/まわ]りの}{[人/ひと]たちに、}{[積極的/せっきょくてき]に}{[日本語/にほんご]で}{[話/はな]しかけましょう。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{また、}{『[日本語/にほんご]でケアナビ』『にほんごをまなぼう』『MARUGOTO』}{などの}{ウェブサイトは、}{[介護/かいご]や}{[日本/にほん][文化/ぶんか]について}{[簡単/かんたん]な}{[日本語/にほんご]で}{[書/か]かれています。}{[日本語/にほんご]と、}{[介護/かいご][文化/ぶんか]}{などを}{[一緒/いっしょ]に}{[勉強/べんきょう]する}{ことが}{できます。}`}</RubyConvert>
                </p>
                <div class={css.links}>
                  <p>
                    <RubyConvert>{`{[日本/にほん]が}{[認/みと]めた}{[団体/だんたい]が}{つくった、}{[介護/かいご]の}{[仕事/しごと]で}{[使/つか]う}{[日本語/にほんご]を}{[学/まな]ぶ}{サイト}`}</RubyConvert>
                    <a
                      href={`https://eng.nihongodecarenavi.jp`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      <RubyConvert>{`{『[日本語/にほんご]でケアナビ』}`}</RubyConvert>
                    </a>
                    <RubyConvert>{`｜{[国際/こくさい][交流/こうりゅう][基金/ききん]}`}</RubyConvert>
                    <br />
                    <small>
                      (
                      <RubyConvert>{`{[日本語/にほんご]}／{[英語/えいご]}／{インドネシア[語/ご]}`}</RubyConvert>
                      )
                    </small>
                  </p>
                  <p>
                    <RubyConvert>{`{[日本/にほん]が}{[認/みと]めた}{[団体/だんたい]が}{つくった、}{[介護/かいご]の}{[仕事/しごと]で}{[使/つか]う}{[日本語/にほんご]を}{[学/まな]ぶ}{サイト}`}</RubyConvert>
                    <a
                      href={`https://aft.kaigo-nihongo.jp/rpv`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      <RubyConvert>{`{『にほんごをまなぼう』}`}</RubyConvert>
                    </a>
                    <RubyConvert>{`｜{[日本/にほん][介護/かいご][福祉士/ふくしし][会/かい]}`}</RubyConvert>
                    <br />
                    <small>
                      (
                      <RubyConvert>{`{[日本語/にほんご]}／{[英語/えいご]}`}</RubyConvert>
                      )
                    </small>
                  </p>
                  <p>
                    <RubyConvert>{`{[日本/にほん]が}{[認/みと]めた}{[団体/だんたい]が}{つくった、}{[日本語/にほんご]と}{[日本/にほん][文化/ぶんか]を}{[学/まな]ぶ}{サイト}`}</RubyConvert>
                    <a
                      href={`https://marugotoweb.jp/ja/index.php`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      <RubyConvert>{`{『MARUGOTO』}`}</RubyConvert>
                    </a>
                    <RubyConvert>{`｜{[国際/こくさい][交流/こうりゅう][基金/ききん]}`}</RubyConvert>
                    <br />
                    <small>
                      (
                      <RubyConvert>{`{[日本語/にほんご]}／{[英語/えいご]}／{スペイン[語/ご]}`}</RubyConvert>
                      )
                    </small>
                  </p>
                </div>
              </AnswerBox>
            </QuestionAndAnswerToggle>
            <QuestionAndAnswerToggle
              isTall={true}
              fullWidth={true}
              question={RubyConverterTool(
                `{[日本/にほん]の}{[生活/せいかつ]で}{[気/き]をつける}{ことは}{ありますか？}`
              )}
            >
              <AnswerBox>
                <p>
                  <RubyConvert>{`{みなさんの}{[母国/ぼこく]と}{[違/ちが]う}{[生活/せいかつ]の}{ルールが}{いくつか}{あります。}{たとえば、}{ゴミを}{わけて}{[捨/す]てたり、}{[使/つか]い[終/お]わった}{トイレットペーパーを}{トイレに}{[流/なが]したり}{する}{ことです。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{[日本/にほん]の}{[生活/せいかつ][情報/じょうほう]については、}{[次/つぎ]の}{ウェブサイトにも}{[書/か]かれています。}`}</RubyConvert>
                </p>
                <div class={css.links}>
                  <p>
                    <RubyConvert>{`{[財団/ざいだん]が}{つくった、}{[外国人/がいこくじん]のための}`}</RubyConvert>
                    <a
                      href={`http://www.clair.or.jp/tagengo`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      <RubyConvert>{`{「[多/た][言語/げんご][生活/せいかつ][情報/じょうほう]」}`}</RubyConvert>
                    </a>
                    <RubyConvert>{`｜{[自治体/じちたい][国際/こくさい][化/か][協会/きょうかい]}`}</RubyConvert>

                    <br />
                    <small>
                      (
                      <RubyConvert>{`{やさしい[日本語/にほんご]}／{[日本語/にほんご]}／{[英語/えいご]}／{[中国語/ちゅうごくご]}／{[韓国/かんこく]・[朝鮮語/ちょうせんご]}／{フィリピノ[語/ご]}／{ベトナム[語/ご]}／{タイ[語/ご]}／{インドネシア[語/ご]}／{ビルマ[語/ご]}／{ロシア[語/ご]}／{ドイツ[語/ご]}／{フランス[語/ご]}／{スペイン[語/ご]}／{ポルトガル[語/ご]}`}</RubyConvert>
                      )
                    </small>
                  </p>
                  <p>
                    <RubyConvert>{`{[国/くに]が}{つくった}`}</RubyConvert>
                    <a
                      href={`http://www.moj.go.jp/nyuukokukanri/kouhou/nyuukokukanri10_00055.html`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      <RubyConvert>{`{「[外国人/がいこくじん][生活/せいかつ][支援/しえん]ポータルサイト」}`}</RubyConvert>
                    </a>
                    <RubyConvert>{`｜{[法務省/ほうむしょう]}`}</RubyConvert>
                    <br />
                    <small>
                      (
                      <RubyConvert>{`{やさしい[日本語/にほんご]}／{[日本語/にほんご]}／{[英語/えいご]}／{ベトナム[語/ご]}`}</RubyConvert>
                      )
                    </small>
                  </p>
                  <p>
                    <RubyConvert>{`{[国/くに]が}{つくった}`}</RubyConvert>
                    <a
                      href={`https://www.bunka.go.jp/english/policy/japanese_language/education/handbook`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      <RubyConvert>{`{「Handbook}{for}{Learning}{Japanese}{and}{Life}{in}{Japan}」`}</RubyConvert>
                    </a>
                    <RubyConvert>{`｜{[文化庁/ぶんかちょう]}`}</RubyConvert>
                    <br />
                    <small>
                      (
                      <RubyConvert>{`{[日本語/にほんご]}／{[英語/えいご]}／{[中国語/ちゅうごくご]}／{[韓国/かんこく]・[朝鮮語/ちょうせんご]}／{スペイン[語/ご]}／{ポルトガル[語/ご]}`}</RubyConvert>
                      )
                    </small>
                  </p>
                </div>
              </AnswerBox>
            </QuestionAndAnswerToggle>
            <QuestionAndAnswerToggle
              isTall={true}
              fullWidth={true}
              question={RubyConverterTool(
                `{[毎日/まいにち]の}{お[祈/いの]りは}{できますか？}`
              )}
            >
              <AnswerBox>
                <p>
                  <RubyConvert>{`{[日本/にほん]の}{[介護/かいご][施設/しせつ]は、}{みなさんの}{[宗教/しゅうきょう]や}{[文化/ぶんか]を}{[尊重/そんちょう]しなければ}{ならない}{ことに}{なっています。}{[宗教/しゅうきょう]や}{[文化/ぶんか]の}{[違/ちが]いを}{きちんと}{[理解/りかい]する}{[施設/しせつ]も}{[増/ふ]えてきています。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{ただし、}{[仕事/しごと][中/ちゅう]にも}{[礼拝/れいはい]が}{できるか、}{ヒジャブ（Hijab）を}{[被/かぶ]っても}{いいか}{などは、}{みなさんが}{[働/はたら]く}{[施設/しせつ]によって}{[違/ちが]います。}{[不安/ふあん]や}{[疑問/ぎもん]が}{ある}{[人/ひと]は、}{[募集/ぼしゅう]や}{[面接/めんせつ]の}{ときに}{[確認/かくにん]すると}{いいでしょう。}`}</RubyConvert>
                </p>
              </AnswerBox>
            </QuestionAndAnswerToggle>
            <QuestionAndAnswerToggle
              isTall={true}
              fullWidth={true}
              question={RubyConverterTool(
                `{[食事/しょくじ]が}{あわなかったら、}{どう}{したら}{いいですか？}`
              )}
            >
              <AnswerBox>
                <p>
                  <RubyConvert>{`{[日本/にほん]では}{[和食/わしょく]の}{ほかに、}{いろいろな}{[国/くに]や[地域/ちいき]の}{[料理/りょうり]を}{[食/た]べる}{ことが}{できます。}{[母国/ぼこく]の}{[食材/しょくざい]が}{[買/か]える}{スーパーや}{オンラインショップも}{あり、}{[自分/じぶん]で}{[料理/りょうり]を}{つくる}{[人/ひと]も}{たくさん}{います。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{レストランも}{たくさん}{あるので、}{[自分/じぶん]に}{あった}{[食事/しょくじ]を}{[見/み]つけられると}{[思/おも]います。}`}</RubyConvert>
                </p>
              </AnswerBox>
            </QuestionAndAnswerToggle>
            <QuestionAndAnswerToggle
              isTall={true}
              fullWidth={true}
              question={RubyConverterTool(
                `{[家族/かぞく]には}{いくら}{[仕送/しおく]りが}{できますか？}`
              )}
            >
              <AnswerBox>
                <p>
                  <RubyConvert>{`{[給与/きゅうよ]は}{[施設/しせつ]によって}{[違/ちが]いますが、}{[介護/かいご][職員/しょくいん]の}{1か[月/げつ]の}{[給与/きゅうよ]は}{[平均/へいきん]で} `}</RubyConvert>
                  <span>
                    <span className={`phrase`}>
                      <ruby>
                        <span className={`rb`}>約</span>
                        <rt>やく</rt>
                      </ruby>
                      18
                      <ruby>
                        <span className={`rb`}>万</span>
                        <rt>まん</rt>
                      </ruby>
                      <ruby>
                        <span className={`rb`}>円</span>
                        <rt>えん</rt>
                      </ruby>
                      です<sup>※</sup>。
                    </span>
                  </span>
                  <RubyConvert>{`{そこから}{[社会/しゃかい][保険/ほけん][料/りょう]や}{[税金/ぜいきん]が}{[引/ひ]かれます。}{[残/のこ]った}{[金額/きんがく]から、}{[家賃/やちん]、}{[電気/でんき]・ガス・[水道/すいどう]}{などの}{[光熱/こうねつ][費/ひ]、}{Wi-Fi[代/だい]、}{[食費/しょくひ]}{などの}{[生活/せいかつ][費/ひ]を}{[支払/しはら]います。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{この}{ウェブサイトに}{[出/で]てくる}{[先輩/せんぱい]たちに}{[聞/き]くと、}{[家族/かぞく]への}{[仕送/しおく]りは}{1か[月/げつ]に}{3～6[万/まん][円/えん]くらいが}{[多/おお]いようです。}`}</RubyConvert>
                </p>
                <ul className={`notes`}>
                  <li>
                    <i>※</i>
                    <span>
                      厚生労働省「
                      <a
                        href={`https://www.mhlw.go.jp/toukei/saikin/hw/kaigo/jyujisya/19/dl/30gaiyou.pdf`}
                        rel={`noopener noreferrer`}
                        target={`_blank`}
                      >
                        介護従事者処遇状況等調査結果の概要
                      </a>
                      」（2018年）より。
                    </span>
                  </li>
                </ul>
              </AnswerBox>
            </QuestionAndAnswerToggle>
            <QuestionAndAnswerToggle
              isTall={true}
              fullWidth={true}
              question={RubyConverterTool(
                `{[地震/じしん]や}{[津波/つなみ]、}{[台風/たいふう]が}{[来/き]たら}{どう}{したら}{いいですか？}`
              )}
            >
              <AnswerBox>
                <p>
                  <RubyConvert>{`{[日本/にほん]では、}{[地震/じしん]・[津波/つなみ]、}{[台風/たいふう]、}{[大雨/おおあめ]・[洪水/こうずい]、}{[大雪/おおゆき]、}{[土砂/どしゃ][災害/さいがい]}{など、}{いろいろな}{[災害/さいがい]が}{[起/お]きます。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{[災害/さいがい][情報/じょうほう]は『[気象庁/きしょうちょう]』が}{[発表/はっぴょう]します。}{インターネット・}{テレビ・}{ラジオ、}{または}{[災害/さいがい][情報/じょうほう]を}{[教/おし]えてくれる}{アプリで}{[情報/じょうほう]を}{[確認/かくにん]する}{ことが}{できます。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{また、}{[水/みず]や}{[食料/しょくりょう]を}{[買/か]っておく}{など、}{[普段/ふだん]から}{[災害/さいがい]に}{[備/そな]えておく}{ことも}{[大切/たいせつ]です。}{そして、}{[実際/じっさい]に}{[災害/さいがい]が}{[起/お]きた}{ときは、}{[落/お]ち[着/つ]いて}{[行動/こうどう]しましょう。}`}</RubyConvert>
                </p>
                <div class={css.links}>
                  <p>
                    <RubyConvert>{`{[国/くに]が}{つくった}`}</RubyConvert>
                    <a
                      href={`https://www.jma.go.jp/jma/kokusai/multi.html`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      <RubyConvert>{`「{Multilingual}{Information}{on}{Disaster}{Mitigation}」`}</RubyConvert>
                    </a>
                    <RubyConvert>{`｜{[気象庁/きしょうちょう]}`}</RubyConvert>
                    <br />
                    <small>
                      (
                      <RubyConvert>{`{[日本語/にほんご]}／{[英語/えいご]}／{[中国語/ちゅうごくご]}／{[韓国/かんこく]・[朝鮮語/ちょうせんご]}／{フィリピノ[語/ご]}／{ベトナム[語/ご]}／{タイ[語/ご]}／{インドネシア[語/ご]}／{ネパール[語/ご]}／{スペイン[語/ご]}／{ポルトガル[語/ご]}`}</RubyConvert>
                      )
                    </small>
                  </p>
                  <p>
                    <RubyConvert>{`{[国/くに]が}{[監修/かんしゅう]した}{[災害/さいがい][情報/じょうほう]アプリ}`}</RubyConvert>
                    <a
                      href={`https://www.rcsc.co.jp/safety`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      <RubyConvert>{`「{Safety}{tips}」`}</RubyConvert>
                    </a>
                    <RubyConvert>{`｜{アールシーソリューション}`}</RubyConvert>
                    <br />
                    <small>
                      (
                      <RubyConvert>{`{[日本語/にほんご]}／{[英語/えいご]}／{[中国語/ちゅうごくご]}／{[韓国/かんこく]・[朝鮮語/ちょうせんご]}／{フィリピノ[語/ご]}／{ベトナム[語/ご]}／{タイ[語/ご]}／{インドネシア[語/ご]}／{ネパール[語/ご]}／{スペイン[語/ご]}／{ポルトガル[語/ご]}`}</RubyConvert>
                      )
                    </small>
                  </p>
                </div>
              </AnswerBox>
            </QuestionAndAnswerToggle>
          </div>
        </CenterContentLayout>
      </BasicText>
    </LayoutBasic>
  </div>
)
